import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { COMPANY_GET_JOB_APPLICATIONS_GROUP } from '../../components/Helpers/ApiRoutes';
import headersData from '../headers';


export const jobGroupReducers = createApi({
  reducerPath: 'jobGroupReducers',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) =>headersData(headers),
  }),
  tagTypes: ['jobs'],
  endpoints: (builder) => ({
    getJobsGroupApplications: builder.query({
      query: (query) => COMPANY_GET_JOB_APPLICATIONS_GROUP+`?group=${query?.groupId}&job=${query?.jobId}&page=${query?.page}&search=${query?.search}`,
      providesTags: ['JobsApplications'],
    }),
  }),
},

);

export const {
  useGetJobsGroupApplicationsQuery,
} = jobGroupReducers;
