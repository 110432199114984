import { COMPANY_CANDIDATE_LOGIN } from "./ApiRoutes";
import { fetchData } from "./Helper";


export const userRoleLogin = (id,role='',context, setContext,navigate) =>{
    fetchData(`${COMPANY_CANDIDATE_LOGIN}/${id}`, "GET", "", true, true, (res) => {
        if (res.status) {
            localStorage.removeItem("setting-active-tab")
            localStorage.setItem('recruitment-token',res?.body?.access_token)
            localStorage.setItem('admin-recruitment-token',context?.auth?.access_token)
            localStorage.setItem('role',role)
            setContext(prevState => ({ ...prevState, auth: res?.body }))
            window.location.replace('/dashboard')
            
        }
    });
    
}
export const goToAdmin = () =>{
    localStorage.removeItem('role')
    localStorage.setItem('recruitment-token',localStorage.getItem('admin-recruitment-token'))
    localStorage.removeItem('admin-recruitment-token')
    localStorage.removeItem("setting-active-tab")
    window.location.replace('/dashboard')
}
