
import { Link } from "react-router-dom"

function BreadCrumbs(props) {

    return (
        <div className="block-header">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>{props.title}</h2>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item  ">
                            <Link to="/dashboard"><i className={"fa fa-dashboard me-2 ms-1"}></i>/</Link>
                        </li>
                        <i className={`mt-1 me-2 ms-2 ${props.icon ?? ""}`}></i> {props.children}
                    </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 props_btn text-end ar_text_btn mt-3 mt-lg-0">
                    {props.btn}
                </div>
            </div>
        </div>
    )
}

export default BreadCrumbs;