const TOKEN = localStorage.getItem('recruitment-token')
const headersData = (headers)=>  {
  headers.set("Accept", "application/json")
  headers.set("Access-Control-Allow-Origin", window.location.origin)
  headers.set("Access-Control-Allow-Methods", 'GET, PUT, POST, DELETE, HEAD, PATCH, OPTIONS')
  headers.set("localization", localStorage.getItem('localization') ?? 'en')
  headers.set("Content-Type", "application/json")
  headers.set('contentType', false)
  headers.set('processData',false)
  headers.set("Authorization",'Bearer ' + TOKEN)

    return headers
}
export default headersData