import { Link, useNavigate } from "react-router-dom";
import ChangeLanguage from "../Lang/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { fetchData } from "../Helpers/Helper";
import { useContext, useEffect, useState } from "react";
import Context from "../Helpers/Context";
import {
  LOGOUT,
  READ_NOTIFICATIONS,
  UNREAD_NOTIFICATIONS,
} from "../Helpers/ApiRoutes";
import { goToAdmin } from "../Helpers/userLoginFromAdmin";
const Header = () => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(Context);
  const [NotificationCount, setNotificationCount] = useState(0);
  const [UnreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [AlertNotificationCount, setAlertNotificationCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    var jsElm = document.createElement("script");
    jsElm.type = "application/javascript";
    jsElm.src = window.location.origin + "/assets/js/common.js";
    document.body.appendChild(jsElm);
  }, []);

  useEffect(() => {
    function readNotification() {
      fetchData(UNREAD_NOTIFICATIONS, "GET", "", true, false, (res) => {
        if (res.status) {
          setNotificationCount((prev) => res.body.notifications);
          setUnreadNotificationCount((prev) => res.body.unreadNotifications);
          setAlertNotificationCount((prev) => res.body.alertNotifications);
        }
      });
    }
    readNotification();
    let notificationInterval = setInterval(
      () => readNotification(),
      [10000 * 5]
    );
    return () => clearInterval(notificationInterval);
  }, []);

  const handleLogout = () => {
    fetchData(LOGOUT, "POST", "", true, false, (res) => {
      if (res.status) {
        setContext((prevState) => ({ ...prevState, auth: "" }));
        localStorage.removeItem("recruitment-token");
        localStorage.removeItem("admin-recruitment-token");
        localStorage.removeItem("role");
        navigate("/sign-in");
      }
    });
  };

  const markAsRead = () => {
    fetchData(READ_NOTIFICATIONS, "POST", "", true, false, (res) => {
      if (res.status) {
        setNotificationCount((prev) => res.body.notifications);
        setUnreadNotificationCount((prev) => res.body.unreadNotifications);
        setAlertNotificationCount((prev) => res.body.alertNotifications);
      }
    });
  };

  return (
    <>
      <nav id="nav-sidebar" className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-brand">
            <button type="button" className="btn-toggle-offcanvas me-2">
              <i className="fa fa-bars"></i>
            </button>
            <button type="button" className="btn-toggle-fullwidth">
              <i className="fa fa-bars"></i>
            </button>
            {localStorage.getItem("role") === "candidate" ? (
              <>
                <span
                  onClick={() => navigate("/")}
                  style={{
                    border: "1px solid #59C4BC",
                    paddingLeft: "47px",
                    paddingRight: "47px",
                    paddingBottom: "9px",
                    paddingTop: "9px",
                    cursor: "pointer",
                    color: "white",
                    borderRadius: "15px",
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-home"></i>&nbsp;&nbsp;
                <span className="candiate_recruitment_btn">  {t("recruitment_portal")}</span>
                </span>
                &nbsp;&nbsp;
                <span
                  onClick={() => goToAdmin()}
                  style={{
                    border: "1px solid #59C4BC",
                    paddingLeft: "47px",
                    paddingRight: "47px",
                    paddingBottom: "9px",
                    paddingTop: "9px",
                    cursor: "pointer",
                    color: "white",
                    borderRadius: "15px",
                    fontSize: "20px",
                  }}
                >
                  <i className="fa-solid fa-user-gear"></i>&nbsp;&nbsp;<span className="candidate_recruitment_portal_btn">{t("go_to_admin")}</span> 
                </span>
              </>
            ) : (
              <Link to="/">
                <i className="fa fa-home"></i>{" "}
                <span className="recruitment-logo">
                  {" "}
                  {t("recruitment_portal")}
                </span>
              </Link>
            )}
          </div>
          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <div className="dropdown notification-css">
                    <button className="dropbtn icon-menu">
                      <i className="fa fa-bell"></i>
                      <span
                        className="number"
                        id="bell-notNotificationCountification-count"
                      >
                        {NotificationCount}
                      </span>
                    </button>
                    <div className="dropdown-content p-2 rounded">
                      <button
                        disabled={!NotificationCount}
                        className="btn btn-primary w-100 mb-1 text-start"
                        onClick={markAsRead}
                      >
                        {t("mark_as_read")}
                      </button>
                      <button
                        className="btn btn-primary w-100 text-start mb-1"
                        onClick={() => navigate("/notifications")}
                      >
                        {t("go_to_notification")}{" "}
                        <small
                          id="notification-alerts"
                          className="text-right badge bg-secondary notification-badge notification-alerts"
                        >
                          {UnreadNotificationCount}
                        </small>{" "}
                      </button>

                      {context &&
                        context.auth &&
                        context.auth.role === "candidate" && (
                          <button
                            className="btn btn-primary w-100 text-start "
                            onClick={() => navigate("/job-alert")}
                          >
                            {t("go_to_job_alert")}{" "}
                            <small
                              id="notification-job-alert"
                              className="text-right badge bg-secondary notification-badge notification-job-alert"
                            >
                              {AlertNotificationCount}
                            </small>{" "}
                          </button>
                        )}
                    </div>
                  </div>
                </li>
                <li title={t("nav.logout")}>
                  <button
                    title={t("nav.logout")}
                    className="icon-menu"
                    onClick={handleLogout}
                  >
                    <i className="fa fa-power-off"></i>
                  </button>
                </li>
              </ul>
            </div>
            <ChangeLanguage />
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
