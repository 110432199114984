import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import hi from "./components/Lang/hi.json";
import en from "./components/Lang/en.json";
import ar from "./components/Lang/ar.json";

const resources = {
    hi: { translation: hi },
    en: { translation: en },
    ar: { translation: ar },
};

function checkKey(lang, value){
    let key = localStorage.getItem(`${lang}_localization_key`);
    key = key ? JSON.parse(key): []
    if(!key.includes(value)) key.push(value);
    localStorage.setItem(`${lang}_localization_key`, JSON.stringify(key));
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('localization') ?? 'en',
        // fallbackLng: 'en',
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
            checkKey('en', key)
        }
    });

export default i18n;
