import { useTranslation } from "react-i18next"
import { noImageHandle } from "../Helpers/Helper";
import { NavLink } from "react-router-dom";

const FrontendFooter = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="footer-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <NavLink to="/">
                                        <img src="/img/light-logo150x40.svg" alt="logo" height="45" onError={noImageHandle} />
                                    </NavLink>
                                </div>
                                <p>{t("footer_short_description")}</p>
                                <div className="footer-social">
                                    <NavLink to="#"><i className="bx bxl-facebook"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-twitter"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-pinterest-alt"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-linkedin"></i></NavLink>
                                </div>
                            </div>
                        </div> 
                        <div className="mt-4 sm-mt-0 col-lg-3 col-sm-6">
                            <div className="footer-widget pl-60">
                                <h3>{t("for_candidate")}</h3>
                                <ul>
                                    <li>
                                        <NavLink to="/dashboard"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.account")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.faq")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/privacy-policy"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.privacy_policy")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.terms")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/blog"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.blogs")}</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-4 lg-mt-0 col-lg-3 col-sm-6">
                            <div className="footer-widget pl-60">
                                <h3>{t("quick_links")}</h3>
                                <ul>
                                    <li>
                                        <NavLink to="/"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.home")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about-us"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.about")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/jobs"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.jobs")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact-us"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.contact")}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="pricing"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.pricing")}</NavLink>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div className="mt-4 lg-mt-0 col-lg-3 col-sm-6">
                            <div className="footer-widget footer-info">
                                <h3>{t("information")}</h3>
                                <ul>
                                    <li>
                                        <span>
                                            <i className="bx bxs-phone"></i> {t("form.phone")}:{" "}
                                        </span>
                                        <a href={`tel:${t("site.phone")}`}>{t("site.phone")}</a>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="bx bxs-envelope"></i> {t("form.email")}:{" "}
                                        </span>
                                        <a href={`mailto:${t("site.email")}`}>{t("site.email")}</a>
                                    </li>
                                    <li>
                                        <span>
                                            <i className="bx bx-location-plus"></i> {t("form.address")}:{" "}
                                        </span>
                                        {t("site.address")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button id="goToTopButton"><i className="fa-solid fa-arrow-up"></i></button> */}
            </footer>
        </>
    )
}

export default FrontendFooter