import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function ManagePage({ code, skeleton }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('loading')
  const [subTitle, setSubTitle] = useState('please_wait')

  useEffect(() => {
    if (code) setTitle(code)
    if (code === 404) setSubTitle('opps_page_not_found')
    else if (code === 503) setSubTitle('access_denied')
    else if (code === 200) setSubTitle('work_in_progress')
  }, [code])

  return (
    skeleton ? <>
      <SkeletonTheme baseColor="#dee2e630">
        <style jsx="true">{`body {overflow:hidden;}`}</style>
        <Skeleton height={"100vh"} />
      </SkeletonTheme>
    </> : <>
      <div className="container-fluid container-common search-error error">
        <div className="row justify-content-center">
          <section className="d-flex align-items-center align-middle p-5 w-100 start-0 top-0 h-100">
            <style jsx="true">{`
                        h1{
                            font-size: calc(  2vw + 100px);
                        }
                        h5{
                            font-size: calc( 3vw + 16px);
                        }
                        .search-error{
                            min-height:100vh;
                            display:flex;
                            justify-content:center;
                            align-items:center;
                        }
                        .error {
                            color: #20293F;
                            box-shadow: 0 5px 0px -2px #20293F;
                            text-align: center;
                            animation: fadein 1.4s ease-in;
                          }
                           .code {
                            font-size: 10.5em;
                            text-shadow:  0 6px 1px rgba(0,0,0,0.0980392) , 0 0 5px rgba(0,0,0,0.0980392) , 0 1px 3px rgba(0,0,0,0.298039) , 0 3px 5px rgba(0,0,0,0.2) , 0 5px 10px rgba(0,0,0,0.247059) , 0 10px 10px rgba(0,0,0,0.2) , 0 20px 20px rgba(0,0,0,0.14902) ;
                            margin: 0;
                          }
                          .desc {
                            text-shadow: 0px 3px 5px rgba(0,0,0,0.5), 0px 6px 20px rgba(0,0,0,0.3);
                            font-weight: 400;
                          }
                          @keyframes fadein {
                            0% {
                              margin-top: -50px;
                              opacity: 0;
                            }
                            50% {
                              opacity: 0.7;
                            }
                            100% {
                              opacity: 1;
                            }
                          }
                          .page-loader-wrapper{
                            background: none;
                          }
                    `}</style>
            <div className="col-sm-12 text-center">
              <h1 className="fw-bolder text-orange my-5 code">{code === 200 ? <i className="bi bi-emoji-smile-fill"></i> : t(title)}</h1>
              <h5 className="fw-bolder text-orange my-5">{t(subTitle)}</h5>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default ManagePage;