
import React from 'react'
import { Link } from 'react-router-dom'
import "./payment-failed.css";
import { useTranslation } from 'react-i18next';

const PaymentUnauthorized = () =>{
    
    const { t } = useTranslation();


    return (
        <>
            <section className="payment-failed-section ptb-100">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-6 col-lg-5 mx-auto'>
                            <div className='payment-failed-content'>
                                <div className='payment-failed-left-img'>
                                    <img src="./img/payment-failed-left.png" alt="" />
                                </div>
                                <h4 className='text-danger'>{t('payment_unauthorized')}</h4>
                                <div class="mt-3 mt-md-4">
                                <Link to="/pricing" className="payment-success-btn">{t("go_to_pricing")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default PaymentUnauthorized