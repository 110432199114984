import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { noImageHandle } from "../Helpers/Helper";
import ChangeLanguage from "../Lang/ChangeLanguage";
import { useContext } from "react";
import Context from "../Helpers/Context";
import { isCompany } from "../Helpers/RoleHelper";
import $ from "jquery"
const FrontendHeader = () => {
  const { t } = useTranslation();
  const [context] = useContext(Context)
  // const [isOpen, setIsOpen] = useState(false);

  const handleNav = () =>{
    $('#navbarNavDropdown').removeClass('show');
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-primary py-md-3">
        <div className="container px-3">
          <NavLink className="navbar-brand" to="/home">
            <img src="/img/light-logo150x40.svg" alt="logo" height="45" onError={noImageHandle} />
          </NavLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav m-auto">
              <li className="nav-item me-1 me-lg-2 me-xl-3 " onClick={()=>handleNav()}>
                <NavLink className="nav-link active" to="/home">{t("nav.home")}</NavLink>
              </li>
              <li className="nav-item me-1 me-lg-2 me-xl-3 " onClick={()=>handleNav()}>
                <NavLink className="nav-link" to="/Jobs">{t("nav.jobs")}</NavLink>
              </li>
              {isCompany && <>
                <li className="nav-item me-1 me-lg-2 me-xl-3 " onClick={()=>handleNav()}>
                  <NavLink className="nav-link" to="/candidate">{t("nav.candidates")}</NavLink>
                </li>
              </>}
              <li className="nav-item me-1 me-lg-2 me-xl-3 " onClick={()=>handleNav()}>
                <NavLink className="nav-link" to="/blog">{t("nav.blogs")}</NavLink>
              </li>
              <li className="nav-item" onClick={()=>handleNav()}>
                <NavLink className="nav-link me-1 me-lg-2 me-xl-3 " to="/pricing">{t("nav.pricing")}</NavLink>
              </li>
              <li className="nav-item me-1 me-lg-2 me-xl-3 " onClick={()=>handleNav()}>
                <NavLink className="nav-link" to="/about-us">{t("nav.about_us")}</NavLink>
              </li>

             
              <li className="nav-item" onClick={()=>handleNav()}>
                <NavLink className="nav-link me-1 me-lg-2 me-xl-3 " to="/contact-us">{t("nav.contact_us")}</NavLink>
              </li>
              
             
            </ul>
           
          </div>
          <div className="other-option d-flex gap-3 manage_header_btn btn_above_mobile">
              <ChangeLanguage />
              {localStorage.getItem("recruitment-token") || context && context.auth ? <NavLink className="signin-btn" to="/dashboard">{t("nav.dashboard")}</NavLink> : <NavLink className="signin-btn" to="/sign-in">{t("nav.sign_in")}</NavLink>}
            </div>
        </div>
        <div className="container px-3 btn_below_mobile pb-2">
        <div className="other-option d-flex gap-3 manage_header_btn">
              <ChangeLanguage />
              {localStorage.getItem("recruitment-token") || context && context.auth ? <NavLink className="signin-btn" to="/dashboard">{t("nav.dashboard")}</NavLink> : <NavLink className="signin-btn" to="/sign-in">{t("nav.sign_in")}</NavLink>}
            </div>
            </div>
      </nav>
    </>
  )
}

export default FrontendHeader