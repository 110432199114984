import "./style.scss"
const DotRoundLoader = (props) => {
    
    return (
        <>
            <style jsx="true">{`
        .application-loader{
            width: 90px;
            height: 50px;
            margin-top: 200px;
        }
        .application-loader-low{
            width: 90px;
            height: 50px;
            margin-top: 120px;
        }
        .loader-div{
            height: 690px;
        }
        .loader-div-low{
            height: 200px;
        }



          
      `}</style>
            {
                props.threeDot?
                <div className="spinner">
  <div className="bounce1"></div>
  <div className="bounce2"></div>
  <div className="bounce3"></div>
</div>
                :
                <div className={props?.low ? `loader-div-low row justify-content-center align-item-center` : `loader-div row justify-content-center align-item-center`}>
                <img alt="user" src="//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif" className={props?.low ? "application-loader-low" : "application-loader"} />
            </div>
            }
        </>
    )
}
export default DotRoundLoader