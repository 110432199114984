import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import headersData from '../headers';
import { COMPANY_DELETE_GROUP, COMPANY_JOB_APPLICATIONS_COLUMN, COMPANY_PUT_JOB_MOVE_GROUP, COMPANY_UPDATE_GROUP, GET_COMPANY_JOB_APPLICATIONS } from '../../components/Helpers/ApiRoutes';


export const jobApplicatonsReducers = createApi({
  reducerPath: 'jobApplicatonsReducers',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) =>headersData(headers),
  }),
  tagTypes: ['jobs'],
  endpoints: (builder) => ({
    getJobsApplications: builder.query({
      query: (page = 1) => GET_COMPANY_JOB_APPLICATIONS,
      providesTags: ['JobsApplications'],
    }),

    createJobsApplications: builder.mutation({
      query: (payload) => ({
        url: COMPANY_JOB_APPLICATIONS_COLUMN,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['JobsApplications'],
    }),

    updateJobsApplications: builder.mutation({
      query: (payload) => ({
        url: COMPANY_UPDATE_GROUP,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['JobsApplications'],
    }),

    deleteJobsApplications: builder.mutation({
      query: (id) => ({
        url: COMPANY_DELETE_GROUP+"/"+id,
        method: 'DELETE',
      }),
      invalidatesTags: ['JobsApplications'],
    }),

    updateJobsApplicationsMove: builder.mutation({
      query: (payload) => ({
        url: COMPANY_PUT_JOB_MOVE_GROUP,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['JobsApplicationsMove'],
    }),
  }),
},

);

export const {
  useGetJobsApplicationsQuery,
  useCreateJobsApplicationsMutation,
  useUpdateJobsApplicationsMutation,
  useDeleteJobsApplicationsMutation,
  useUpdateJobsApplicationsMoveMutation
} = jobApplicatonsReducers;
