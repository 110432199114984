import { NavLink, useNavigate } from "react-router-dom"
import { useLocation } from 'react-router';
import { useTranslation } from "react-i18next"
import { fetchData, noImageHandle, showAlert } from "../Helpers/Helper";
import { LOGOUT } from "../Helpers/ApiRoutes";
import { useContext, useEffect } from "react";
import Context from "../Helpers/Context";
import { isCandidate, isCompany } from "../Helpers/RoleHelper";
import _ from "lodash";

const Aside = () => {
    const location = useLocation()
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context)
    const navigate = useNavigate()
    const checkLocation = (url) => {
        return url.some((l) => location.pathname.match(l))
    }
    
    const handleLogout = (e) => {
        e.preventDefault();
        fetchData(LOGOUT, 'POST', '', true, false, (res) => {
            if (res.status) {
                setContext(prevState => ({ ...prevState, auth: '' }))
                localStorage.removeItem("recruitment-token");
                localStorage.removeItem("setting-active-tab")
                localStorage.removeItem('profile-done')
                localStorage.removeItem('admin-recruitment-token')
                localStorage.removeItem('role')
                sessionStorage.clear()
                window.location.replace("/sign-in")
            }
        })
    }

    useEffect(() => {
        function preventNav(e) {
            e.preventDefault();
            showAlert({ message: t("please_complete_your_profile_first") });
        }
        if (context && context.auth && context.auth.user_details && context.auth.user_details.profile_complete !== 0) {
            document.querySelectorAll("#left-sidebar-nav ul li a").forEach((ele) => ele.addEventListener("click", preventNav))
        }

        return (() => {
            document.querySelectorAll("#left-sidebar-nav ul li a").forEach((ele) => ele.removeEventListener("click", preventNav));
        })
    }, [context, t])

    const handleRoute = (route,tab) =>{
        localStorage.setItem('setting-active-tab', tab)
        navigate(route)
    }

    return (
        <>
            <div id="left-sidebar" className={`sidebar`}>
                <button type="button" className="btn-toggle-offcanvas"><i className="fa fa-arrow-left"></i></button>
                <div className="sidebar-scroll">
                    <div className="user-account cursor_pointer">
                        {context && context.auth && context.auth.user_details && <>
                            <img onClick={()=>handleRoute("/setting",isCompany ? "profile-tab-pane" :"home-tab-pane")} src={context?.auth?.image_url } className="rounded-circle user-photo" alt="User Profile" onError={noImageHandle} />
                        </>}
                        <div className="dropdown">
                            <span>{t("welcome")}</span>
                            <span onClick={()=>handleRoute("/setting",isCompany ? "profile-tab-pane":"home-tab-pane")}  className="user-name"><strong>{context && context.auth && _.startCase(context.auth.name)}</strong></span>
                        </div>
                        <hr />
                    </div>
                    <div className="tab-content padding-0">
                        <div className="tab-pane active" id="menu">
                            <nav id="left-sidebar-nav" className="sidebar-nav">
                                <ul id="main-menu" className="metismenu li_animation_delay">
                                    <li className={checkLocation(['dashboard']) ? 'active' : ''} >
                                        <NavLink to="/dashboard" title={t("nav.dashboard")}><i className="fa fa-dashboard"></i>{t("nav.dashboard")}</NavLink>
                                    </li>
                                    {isCandidate && <>
                                        <li className={checkLocation(['favourite']) ? 'active' : ''} >
                                            <NavLink to="/favourite-job" title={t("nav.favourite_job")}><i className="fa fa-heart" aria-hidden="true"></i>{t("nav.favourite_job")}</NavLink>
                                        </li>
                                        <li className={checkLocation(['applied-jobs']) ? 'active' : ''} >
                                            <NavLink to="/applied-jobs" title={t("nav.applied_jobs")}><i className="fa fa-briefcase"></i>{t("nav.applied_jobs")}</NavLink>
                                        </li>
                                        <li className={checkLocation(['job-alert']) ? 'active' : ''} >
                                            <NavLink to="/job-alert" title={t("nav.job_alert")}><i className="fa fa-bullhorn" aria-hidden="true"></i>{t("nav.job_alert")}</NavLink>
                                        </li>
                                        
                                    </>}

                                    {isCompany && <>
                                        <li className={checkLocation(['my-jobs', 'create_jobs']) ? 'active' : ''} >
                                            <NavLink to="/my-jobs" title={t("nav.my_jobs")}><i className="fa fa-briefcase" aria-hidden="true"></i>{t("nav.my_jobs")}</NavLink>
                                        </li>
                                        <li className={checkLocation(['candidates']) ? 'active' : ''} >
                                            <NavLink to="/candidates" title={t("nav.candidates")}><i className="fa-solid fa-users" aria-hidden="true"></i>{t("nav.candidates")}</NavLink>
                                        </li>
                                        <li className={checkLocation(['bookmark-candidate']) ? 'active' : ''} >
                                            <NavLink to="/bookmark-candidate" title={t("nav.bookmark_candidate")}><i className="fa fa-bookmark" aria-hidden="true"></i>{t("nav.bookmark_candidate")}</NavLink>
                                        </li>
                                    </>}

                                    <li className={checkLocation(['notifications']) ? 'active' : ''} >
                                        <NavLink to="/notifications" title={t("nav.notifications")}><i className="fa fa-bell" aria-hidden="true"></i>{t("nav.notifications")}</NavLink>
                                    </li>
                                    <li className={checkLocation(['chat']) ? 'active' : ''} >
                                        <NavLink to="/chat" title={t("nav.chat")}><i className="fa fa-comments"></i>{t("nav.chat")}</NavLink>
                                    </li>

                                    {isCompany && <>
                                        <li className={checkLocation(['email']) ? 'active' : ''} >
                                            <NavLink to="/email" title={t("nav.email")}><i className="fa fa-envelope" aria-hidden="true"></i>{t("nav.email")}</NavLink>
                                        </li>
                                        {/* <li className={checkLocation(['mail-gun']) ? 'active' : ''} >
                                            <NavLink to="/mail-gun" title={t("nav.mail_chat")}><i className="fas fa-comment-dots" aria-hidden="true"></i>{t("nav.mail_chat")}</NavLink>
                                        </li> */}
                                        {context && context.auth && context.auth.role !== 'staff' && <>
                                            <li className={checkLocation(['staff']) ? 'active' : ''} >
                                                <NavLink to="/staff" title={t("nav.staff")}><i className="fa fa-users"></i>{t("nav.staff")}</NavLink>
                                            </li>
                                        </>}
                                        {context && context.auth && context.auth.role === 'company' &&<>
                                        <li className={checkLocation(['invoice']) ? 'active' : ''} >
                                            <NavLink to="/invoice" title={t("nav.order_invoice")}><i className="fa fa fa-file-invoice"></i>{t("nav.order_invoice")}</NavLink>
                                        </li>
                                        </>}
                                        <li className={checkLocation(['consultant']) ? 'active' : ''} >
                                            <NavLink to="/consultant" title={t("nav.consultant")}><i className="fas fa-headset"></i>{t("nav.consultant")}</NavLink>
                                        </li>
                                        <li className={checkLocation(['parser-log']) ? 'active' : ''} >
                                            <NavLink to="/parser-log" title={t("nav.parser-log")}><i className="fas fa-file"></i>{t("nav.parser-log")}</NavLink>
                                        </li>
                                    </>}

                                    <li className={checkLocation(['setting']) ? 'active' : ''} >
                                        <NavLink to="/setting" title={t("nav.setting")}><i className="fa fa-gear"></i>{t("nav.setting")}</NavLink>
                                    </li>
                                    <li className={checkLocation(['visitor-log']) ? 'active' : ''} >
                                        <NavLink to="/visitor-log" title={t("nav.visitor_log")}><i className="fas fa-skiing-nordic"></i>{t("nav.visitor_log")}</NavLink>
                                    </li>
                                    <li className="log_out">
                                        <span role="button" style={{fontSize: "15px"}} className="position-absolute ms-4 text-white mt-2" title={t("nav.logout")} onClick={handleLogout}><i className="fa fa-sign-out" aria-hidden="true"></i>{t("nav.logout")}</span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aside
