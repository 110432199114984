import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { jobApplicatonsReducers } from './reducers/jobApplicationsReducer';
import { jobGroupReducers } from './reducers/jobGroupReducers';
import { mailChatReducers } from './reducers/mailChatReducer';

export const store = configureStore({
    reducer: {
      [jobApplicatonsReducers.reducerPath]: jobApplicatonsReducers.reducer,
      [jobGroupReducers.reducerPath]: jobGroupReducers.reducer,
      [mailChatReducers.reducerPath]: mailChatReducers.reducer

    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(jobApplicatonsReducers.middleware,jobGroupReducers.middleware,mailChatReducers.middleware),
  });
  
  setupListeners(store.dispatch);